import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false;
//------------------------------tim-----------------------------------------------------------------
import tim from './tim';
import TIM from 'tim-js-sdk';
window.tim = tim;
window.TIM = TIM;
Vue.prototype.tim = tim;
Vue.prototype.TIM = TIM;
//------------------------------tim结束-----------------------------------------------------------------


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
