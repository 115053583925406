import { formatTime } from './../../utlis/index'
import { decodeElement } from './../../utlis/decodeElement'
// import TIM from 'tim-js-sdk'
// import api  from '@/utils/api'
// import utils from '@/utils/request'
// import {getTimHistoryMsgList} from "@/api/modules/common"

const conversationModules = {
  state: {
    allConversation: [], // 所有的conversation
    currentConversationID: '', // 当前聊天对话ID
    currentConversation: {}, // 当前聊天对话信息
    currentMessageList: [], // 当前聊天消息列表
    nextReqMessageID: '', // 下一条消息标志
    isCompleted: false, // 当前会话消息是否已经请求完毕
    isFinalCompleted: false, // 当前会话消息是否已经请求完毕
    isLoading: false // 是否正在请求
  },
  getters: {
    allConversation: state => state.allConversation,
    // 当前聊天对象的ID
    toAccount: state => {
      if (state.currentConversationID.indexOf('C2C') === 0) {
        return state.currentConversationID.substring(3)
      } else if (state.currentConversationID.indexOf('GROUP') === 0) {
        return state.currentConversationID.substring(5)
      }
    },
    // 当前聊天对象的昵称
    toName: state => {
      if (state.currentConversation.type === 'C2C') {
        return state.currentConversation.userProfile.userID
      } else if (state.currentConversation.type === 'GROUP') {
        return state.currentConversation.groupProfile.name
      }
    },
    // 当前聊天对话的Type
    currentConversationType: state => {
      if (state.currentConversationID.indexOf('C2C') === 0) {
        return 'C2C'
      }
      if (state.currentConversationID.indexOf('GROUP') === 0) {
        return 'GROUP'
      }
      return ''
    },
    currentConversation: state => state.currentConversation,
    currentMessageList: state => state.currentMessageList,
    totalUnreadCount: state => {
      const result = state.allConversation.reduce((count, { unreadCount }) => count + unreadCount, 0)
      if (result === 0) {
        // uni.removeTabBarBadge({ index: 0 })
      } else {
        // uni.setTabBarBadge({ index: 0, text: result > 99 ? '99+' : String(result) })
      }
      return result
    }
  },
  mutations: {
    pushCurrentMessageList(state, data) {
      console.log(data,'ddd');
      // 还没当前会话，则跳过
      if (!state.currentConversation.conversationID) {
        return
      }
      if (Array.isArray(data)) {
        // 筛选出当前会话的消息
        const result = data.filter(item => item.conversationID === state.currentConversation.conversationID)
        const refreshPatient = data.some((message)=>{
          if(message.payload&&((message.payload.extension&&message.payload.extension == '11')||(message.payload.ext&&message.payload.ext == '11'))){
            return true
          }
        })
        if(refreshPatient){
          state.refreshPatient = true
        }
        state.currentMessageList = [...state.currentMessageList, ...result]
      } else if (data.conversationID === state.currentConversation.conversationID) {
        const refreshPatient = (data.payload&&((data.payload.extension&&data.payload.extension == '11')||(data.payload.ext&&data.payload.ext == '11')))
        if(refreshPatient){
          state.refreshPatient = true
        }
        state.currentMessageList = [...state.currentMessageList, data]
      }
    },
    updateConversationList(state, conversationList) {
      state.conversationList = conversationList
    },
    // 历史头插消息列表
    unshiftMessageList (state, messageList) {
      let list = [...messageList]
      for (let i = 0; i < list.length; i++) {
        let message = list[i]
        list[i].virtualDom = decodeElement(message)
        let date = new Date(message.time * 1000)
        list[i].newtime = formatTime(date)
        // list[i].firstTime = uni.dayjs(date).format('YYYY-MM-DD HH:mm:ss')
      }
      state.currentMessageList = [...list, ...state.currentMessageList]
    },
    // 收到
    receiveMessage (state, messageList) {
      let list = [...messageList]
      for (let i = 0; i < list.length; i++) {
        let message = list[i]
        list[i].virtualDom = decodeElement(message)
        let date = new Date(message.time * 1000)
        list[i].newtime = formatTime(date)
      }
      state.currentMessageList = [...state.currentMessageList, ...list]
    },
    sendMessage (state, message) {
      message.virtualDom = decodeElement(message)
      let date = new Date(message.time * 1000)
      message.newtime = formatTime(date)
      state.currentMessageList.push(message)
      setTimeout(() => {
        // uni.pageScrollTo({
        //   scrollTop: 99999
        // })
      }, 800)
    },
    // 更新当前的会话
    updateCurrentConversation (state, conversation) {
      state.currentConversation = conversation
      state.currentConversationID = conversation.conversationID
    },
    // 更新当前所有会话列表
    updateAllConversation (state, list) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].lastMessage && (typeof list[i].lastMessage.lastTime === 'number')) {
          let date = new Date(list[i].lastMessage.lastTime * 1000)
          list[i].lastMessage._lastTime = formatTime(date)
        }
      }
      // uni.setStorageSync('allConversationList',list)
      state.allConversation = list
    },
    // 重置当前会话
    resetCurrentConversation (state) {
      state.currentConversationID = '' // 当前聊天对话ID
      state.currentConversation = {} // 当前聊天对话信息
      state.currentMessageList = [] // 当前聊天消息列表
      state.nextReqMessageID = '' // 下一条消息标志
      state.isCompleted = false // 当前会话消息是否已经请求完毕
      state.isFinalCompleted = false // 当前最终会话消息是否已经请求完毕
      state.isLoading = false // 是否正在请求
    },
    resetAllConversation (state) {
      state.allConversation = []
      // uni.setStorageSync('allConversationList',[])
    },
    removeMessage (state, message) {
      state.currentMessageList.splice(state.currentMessageList.findIndex(item => item.ID === message.ID), 1)
    },
    changeMessageStatus (state, index) {
      state.currentMessageList[index].status = 'fail'
    }
  },
  actions: {
    // 消息事件
    onMessageEvent (context, event) {
      if (event.name === 'onMessageReceived') {
        let id = context.state.currentConversationID
        if (!id) {
          return
        }
        let list = []
        event.data.forEach(item => {
          if (item.conversationID === id) {
            list.push(item)
          }
        })
        context.commit('receiveMessage', list)
      }
    },
    // 获取消息列表
    getMessageList (context) {
       //  inquiry 聊天窗口传过来的聊天数据
      const {currentConversationID, nextReqMessageID} = context.state
      // 判断是否拉完了
      if (!context.state.isCompleted) {
        if (!context.state.isLoading) {
          context.state.isLoading = true
          this.tim.getMessageList({ conversationID: currentConversationID, nextReqMessageID: nextReqMessageID, count: 15 }).then(res => {
            context.state.nextReqMessageID = res.data.nextReqMessageID
            context.commit('unshiftMessageList', res.data.messageList)
            if (res.data.isCompleted) {
              context.state.isCompleted = true
            }
            context.state.isLoading = false
          }).catch(err => {
            console.log(err)
          })
        } else {
          // uni.showToast({
          //   title: '你拉的太快了',
          //   icon: 'none',
          //   duration: 500
          // })
        }
      } else {
          // 拉取历史消息  clientType 客户端类型（1：用户端，2：医生端） doctorId 医生ID   time   userId
        // alert( JSON.stringify(context.state.currentMessageList[0]),'time')
        let  date = context.state.currentMessageList[0] ? context.state.currentMessageList[0].time : new Date().getTime()
        console.log(date,'date')
       //  getTimHistoryMsgList({clientType: '1',userId:uni.getStorageSync('userId'),doctorId:inquiry.doctorId,time:date}).then(res =>{
       //   // utils.request(api.pullHistoryMsgList,{clientType: '1',userId:inquiry.userId, patientId:inquiry.patientId,doctorId:inquiry.doctorId,time:context.state.currentMessageList[0].time}).then(res =>{
       //    let userId = uni.getStorageSync('userId')
       //    if(res.code === 200 && res.data.length > 0){
       //        if(userId){
       //          [...res.data].forEach((e,i)=>{
       //            if(userId == e.from){
       //              res.data[i].flow = 'out'
       //            }else{
       //              res.data[i].flow = 'in'
       //            }
       //          })
       //        }
       //       context.commit('unshiftMessageList', res.data)
       //     }else{
       //        context.state.isFinalCompleted = true
       //        uni.showToast({
       //          title: '没有更多啦',
       //          icon: 'none',
       //          duration: 1500
       //        })
       //     }
       //  }).catch(e=>{
       //    context.state.isFinalCompleted = true
       //     uni.showToast({
       //       title: '没有更多啦',
       //       icon: 'none',
       //       duration: 1500
       //     })
       // })
      }
    },
    checkoutConversationList(context, conversationID){
      console.log(context)
      console.log(conversationID)
      // return new Promise((resolve,reject)=>{
      //   context.commit('resetCurrentConversation')
      //   uni.$app.setMessageRead({ conversationID })
      //   return uni.$app.getConversationProfile(conversationID).then(({ data: { conversation } }) => {
      //     context.commit('updateCurrentConversation', conversation)
      //     context.dispatch('getMessageList')
      //     let name = ''
      //     switch (conversation.type) {
      //       case TIM.TYPES.CONV_C2C:
      //         name = conversation.userProfile.nick || conversation.userProfile.userID
      //         break
      //       case TIM.TYPES.CONV_GROUP:
      //         name = conversation.groupProfile.name || conversation.groupProfile.groupID
      //         break
      //       default:
      //         name = '系统通知'
      //     }
      //     resolve(conversation)
      //   })
      // })
    },
    checkoutConversation (context, conversationObj) {
      console.log(context)
      console.log(conversationObj)
      // let conversationID = conversationObj.doctorId
      // let inquiryId = conversationObj.orderId
      // let noTopage = conversationObj.noTopage
      // context.commit('resetCurrentConversation')
      // uni.$app.setMessageRead({ conversationID })
      // return uni.$app.getConversationProfile(conversationID).then(({ data: { conversation } }) => {
      //     context.commit('updateCurrentConversation', conversation)
      //     context.dispatch('getMessageList')
      //     let name = ''
      //     switch (conversation.type) {
      //       case TIM.TYPES.CONV_C2C:
      //         name = conversation.userProfile.nick || conversation.userProfile.userID
      //         break
      //       case TIM.TYPES.CONV_GROUP:
      //         name = conversation.groupProfile.name || conversation.groupProfile.groupID
      //         break
      //       default:
      //         name = '系统通知'
      //     }
      //     let doctorId = conversationID.substring(3,conversationID.length)
      //   if(!noTopage){
      //     uni.navigateTo({ url: `../chat/main?toAccount=${name}&type=${conversation.type}&orderId=${inquiryId}&doctorId=${doctorId}` })
      //   }
          return  Promise.resolve()
      // })
    }
  }
}

export default conversationModules
