const platform = process.env.NODE_ENV;

const DOMAIN = {
  production: {
    api: 'https://api.tjjh.topdoctorscenter.cn',
    api2: 'https://api.tjjh.topdoctorscenter.cn'
  },
  testing: {
    api: 'https://t.dyh.uidti.com/jh',
    api2: 'https://t.dyh.uidti.com/jh'
  },
  development: {
    api: '/apis',
    api2: 'https://t.dyh.uidti.com/jh'
  }
};
const interfaceData = {
  screenRegister:'/jh/3th/service/screen/register', // 硬件-注册设备
  getUserSign: '/jh/workstation/get_tim_user_sign', //im登录签名
  getBatchRoomQueueStatus: '/jh/workstation/batch_get_room_queue_status',// 分诊屏
};

const getUrl = function (domain, api) {
  return DOMAIN[platform][domain] + interfaceData[api]
};
export default getUrl;
