<template>
    <div class="tvBox">
        <div v-if="isLoading" style="display: flex;flex-direction: row;justify-content: center;align-items: center;height:100vh;color: #fff">
            该诊室暂无数据
        </div>
        <div v-if="!isLoading" style="display: flex;flex-direction: column;height: 100vh">
            <div class="tvTitle">{{filterData.roomName||'暂无关联科室'}}<span style="width: 10px;height: 100%">&nbsp;</span></div>
            <div class="tvContent">
                <div class="left">
                    <div class="content">
                        <div class="houjian">
                            <div>已登记</div>
                            <div >候检中</div>
                        </div>
                        <div class="awaitCustomerName">
<!--                            <div v-for="(item,index) in list1" :key="index">{{item}}</div>-->
                            <div v-for="(item) in filterData.checkingQueue" :key="item.lineUpId"  v-if="filterData.checkingQueue && filterData.checkingQueue.length > 0">{{item.customerName}}</div>
                            <div v-if="filterData.checkingQueue && filterData.checkingQueue.length <=0"  style="padding-top:25%;color: #999;width: 100%;display: flex;flex-direction: row;align-items: center;justify-content: center">暂无候检</div>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <div class="top">
                        <div class="content">
                            <div class="title">待登记</div>
                            <div class="name">
<!--                                <div v-for="(item,index) in list1" :key="index">{{item}}</div>-->
                                <div v-for="(item) in filterData.waitQueue" :key="item.lineUpId" v-if="filterData.waitQueue.length > 0" >{{item.customerName}}</div>
                                <div v-if="filterData.waitQueue.length <= 0" style="color: #999;width: 100%;display: flex;flex-direction: row;align-items: center;justify-content: center">暂无登记</div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom">
                        <div class="content">
                            <div class="text">请未登记的客户，到登记窗口交表登记候检</div>
                            <div class="imgBox">
                                <div class="img"><img src="../assets/img/qrCode.jpg" height="100%" width="100%"/></div>
                                <div class="qrText">扫码查进度</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState,mapGetters } from 'vuex'
    import api from './../api/index'
    // import {getBatchRoomQueueStatus} from "@/api/modules/common"
    export default {
        name: "tvScreen",
        data(){
            return{
                list1:['张小花','王磊磊','王小磊','张雪花','王晶晶晶','张进','李乐乐','张小花','王磊磊','王小磊','张雪花','王晶晶晶','张进','李乐乐','张小花','王磊磊','王小磊','张雪花','王晶晶晶','张进','李乐乐','吴佳佳','张梦羽','林立韩','欧阳画画','张旭','王蒙','旺旺','张丽花','李小小','李静安','李白利','张丽花','李小小','李静安','李白利'],
                lineUpList:[],
                isLoading: false, // 是否加载完成
                filterData:{
                    roomName: '',
                    checkingQueue:[],
                    waitQueue:[],
                },
            }
        },
        created(){
        },
        mounted(){  console.log(this.isSdkReady,'页面')
            console.log(localStorage.getItem('roomInfo'),'dghjgc')
            if(localStorage.getItem('roomInfo')){
                this.getBatchRoomQueueStatus();
            }
        },
        watch: {
            allConversation: {
                handler: function(newValue) {
                    console.log(newValue,'newValue');
                    if(this.isSdkReady&&newValue[0].lastMessage) {
                        let fromAccount = newValue[0].lastMessage.fromAccount;
                        let extension = newValue[0].lastMessage.payload.extension;
                        let data = newValue[0].lastMessage.payload.data ? JSON.parse(newValue[0].lastMessage.payload.data) : {};
                        setTimeout(() => {
                            if (fromAccount == 'tjadmin' && extension == 'event' && data.event == 'UPDATE_PAGE_EVENT') {
                                console.log('im推送接口请求数据');
                                clearInterval(this.timer);
                                if(data.body){
                                    localStorage.setItem('roomInfo',data.body);
                                }
                                this.getBatchRoomQueueStatus();
                            }
                        }, 500);
                    }
                },
                deep: true,
            },
            isSdkReady(){
                console.log('dsafd')
            },
        },
        computed: {
            ...mapState({
                allConversation: state => state.conversation.allConversation,
                isSdkReady: state => state.global.isSdkReady
            }),
            ...mapGetters(['totalUnreadCount', 'myInfo'])
        },
        destroyed(){
            clearInterval(this.timer);
        },
        methods:{
            timing(){
                clearInterval(this.timer);
                this.timer = setInterval( ()=> {
                    console.log(444444);
                    this.getBatchRoomQueueStatus();
                }, 60000 * Number(localStorage.getItem('freqTimeInterval') || this.freqTimeInterval))
            },
            async getBatchRoomQueueStatus(){
                let roomIds =  localStorage.getItem('roomInfo');
                if(!roomIds){
                    this.isLoading = true;
                    return
                } else{
                    this.isLoading = false;
                }
                try {
                    let {code,data} = await api.getBatchRoomQueueStatus({query:{roomIds}});
                    if(code ===200){
                        if(data && data.length > 0 ){
                            data.forEach(item=>{
                                item.waitQueue.length > 0 && item.waitQueue.map((items)=>{
                                    items.customerName =  items.customerName.length > 4 ? items.customerName.substring(0,4) : items.customerName
                                });
                                item.checkingQueue.length > 0 && item.checkingQueue.map((items)=>{
                                    items.customerName =  items.customerName.length > 4 ? items.customerName.substring(0,4) : items.customerName
                                })
                            });
                            let filterData = data.filter(item => item.roomType === '2');
                            this.filterData =  filterData.length > 0 && filterData[0];
                            if( this.filterData.roomName &&  this.filterData.roomName.length === 3){
                                this.filterData.roomName =   '　' +   this.filterData.roomName + '　';
                            }
                            console.log( this.filterData)
                        }
                        this.timing();
                    }
                }catch (e) {
                    clearInterval(this.timer);
                    this.isLoading = true
                }
            },
        }
    }
</script>

<style scoped  lang="scss">
    .tvBox{
        width: 100%;
        height: 100vh;
        background: linear-gradient(180deg,#4abf9b, #1f97b0);
        display: flex;
        flex-direction: column;
        flex: 1;
        .tvTitle{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            text-align: center;
            color: #fff;
            font-weight: 700;
            padding-top: 10px;
            padding-bottom: 5px;
            font-size: 90px;
            letter-spacing:5px;
        }
        .tvContent{
            flex: 1;
            display: flex;
            flex-direction: row;
            /*margin: 0 10px 10px 10px;*/
            margin: 0 10px 15px 10px;
            overflow: hidden;
            .left{
                background: #ffffff;
                border-radius: 4px;
                margin-right: 10px;
                flex: 1;
                width: 68.3%;
                padding: 10px;
                display: flex;
                flex-direction: column;
                .content{
                    border: 1px solid #eeeeee;
                    box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.10), 0px 2px 16px 0px rgba(0,0,0,0.09) inset;
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    overflow: hidden;
                    .houjian{
                        height: 100px;
                        min-height: 100px;
                        background: #1f97b0;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                        font-size: 68px;
                        /*font-size: 72px;*/
                        font-weight: 600;
                        letter-spacing:10px;
                        padding-left: 10px;
                    }
                    .awaitCustomerName{
                        padding: 11px 10px 10px 15px;
                        display: flex;
                        flex-direction: row;
                        /*flex: 1;*/
                        flex-wrap:wrap;
                        /*font-size: 50px;*/
                        font-size: 46px;
                        font-weight: 600;
                        text-align: left;
                        div{
                            width: 25%;
                            padding-bottom: 8px;
                        }
                    }
                }
            }
            .right{
                /*width: 610px;*/
                width: 31.7%;
                display: flex;
                flex-direction: column;
                /*flex: 1;*/
                .top{
                    background: #fff;
                    /*margin-bottom: 30px;*/
                    margin-bottom: 10px;
                    display: flex;
                    flex-direction: row;
                    flex: 1;
                    padding: 10px;
                    overflow: hidden;
                    .content{
                        background: #ffffff;
                        border: 1px solid #eeeeee;
                        box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.10), 0px 2px 16px 0px rgba(0,0,0,0.09) inset;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        flex: 1;
                        overflow: hidden;
                        .title{
                            height: 100px;
                            line-height: 100px;
                            background: #1f97b0;
                            color: #fff;
                            width: 100%;
                            text-align: center;
                            letter-spacing:10px;
                            /*font-size: 72px;*/
                            font-size: 68px;
                            font-weight: 600;
                            box-sizing: border-box;
                            padding-left: 10px;
                        }
                        .name{
                            display: flex;
                            flex-direction: row;
                            flex-wrap:wrap;
                            overflow: hidden;
                            padding: 10px 10px 0px 30px;
                            width: 100%;
                            /*flex: 1;*/
                            div{
                                /*font-size: 64px;*/
                                font-size: 36px;
                                font-weight: 600;
                                width: 50%;
                                text-align: left;
                                padding-bottom: 2.5px;
                            }
                        }
                    }
                }
                .bottom{
                    background: #fff;
                    padding: 10px;
                    box-sizing: border-box;
                    .content{
                        background: #fff;
                        border: 2px solid #eeeeee;
                        border-radius: 6px;
                        box-shadow: 0px 2px 7px 0px rgba(223,223,223,0.50), 2px 0px 16px 0px rgba(218,218,218,0.50) inset;
                        display: flex;
                        flex-direction: row;
                        /*align-items: center;*/
                        padding: 10px 10px;
                        box-sizing: border-box;
                        .text{
                            flex: 1;
                            /*font-size: 50px;*/
                            font-size: 30px;
                            font-weight: 500;
                            text-align: center;
                            color: #ff615b;
                        }
                        .imgBox{
                            margin-left: 20px;
                            .img{
                                width: 80px;
                                /*width: 174px;*/
                                /*height: 174px;*/
                                height: 80px;
                                img{
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .qrText{
                                /*font-size: 29px;*/
                                font-size: 15px;
                                font-weight: 600;
                                color: #000000;
                                text-align: center;
                                padding-top: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
