// import {getUserSign} from '@/api/modules/common'
import api from "./../../api/index"
import tim from '@/tim'
const globalModules = {
  state: {
    isSdkReady: false,
    isCalling: false,
    screenRegisterStatus:false
  },
  getters: {
    isSdkReady: state => state.isSdkReady,
    isCalling: state => state.isCalling,
    screenRegisterStatus:state => state.screenRegisterStatus,
  },
  mutations: {
    toggleIsSDKReady(state, isSDKReady) {
      console.log(isSDKReady,'===================');
      state.isSDKReady = typeof isSDKReady === 'undefined' ? !state.isSDKReady : isSDKReady
      console.log(state.isSDKReady,'state.isSDKReady=============')
    },
    showToast (state, payload) {
      console.log(state)
      console.log(payload)
      // uni.showToast({
      //   title: payload.title,
      //   icon: payload.icon || 'none',
      //   duration: payload.duration || 800
      // })
    },
    setSdkReady (state, payload) {
      state.isSdkReady = payload
    },
    setCalling (state, payload) {
      state.isCalling = payload
    },
    screenRegisterIsok (state, payload) {
      state.screenRegisterStatus = payload
    },
  },
  actions: {
    login({commit,state,dispatch}){  // 登录
      console.log(commit)
      return new Promise( (resolve, reject) => {
        // console.log(async())
        console.log(resolve)
        console.log(reject)
        setTimeout(()=>{
          if(!state.isSdkReady){
            dispatch('iMLogin', false)
          }
        },0)
      })
    },
    iMLogin(context,isSDKReady){
      return new Promise( (resolve, reject) =>{
        if(!isSDKReady){
          let userID = localStorage.getItem('userId');
          if(userID){
            api.getUserSign({query:{timUserId:userID}}).then(res =>{
              if(res.code === 200){
               tim.login({
                  userID:userID.toString(),
                  userSig: res.data
                }).then(res =>{
                  console.log('注册成功');
                  context.dispatch('getImConversationList');
                  resolve(res)
                }).catch(err =>{
                  reject(err)
                })
              }
            })
          }
        }
      })
    },
    getImConversationList({state}){ // 获取消息列表
      console.log(state,'state');
      console.log(state.isSdkReady,'isSdkReady');
      if(state.isSdkReady){
        tim.getConversationList().then(function(imResponse) {
          console.log(imResponse,'imResponse');
          const conversationList = imResponse.data.conversationList;
          this.$store.commit('updateAllConversation', conversationList)
        })
      }
    },
    resetStore (context) {
      context.commit('resetGroup');
      context.commit('resetUser');
      context.commit('resetCurrentConversation');
      context.commit('resetAllConversation')
    }
  }
}

export default globalModules
