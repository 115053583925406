<template>
  <div id="app">
<!--    <div id="nav">-->
<!--      <router-link to="/">Home</router-link> |-->
<!--      <router-link to="/about">About</router-link>-->
<!--    </div>-->
    <router-view/>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import api from "./api/index"
// import store from "./store"
console.log(api);
export default {
    data(){
        return{

        }
    },
    mounted(){
        this.initListener()
    },
    created() {
        this.screenRegister()
    },
    computed: {
        ...mapState({
            currentUserProfile: state => state.user.currentUserProfile,
            currentConversation: state => state.conversation.currentConversation,
            isLogin: state => state.user.isLogin,
            isSDKReady: state => state.user.isSDKReady,
            userID: state => state.user.userID
        }),
        // 是否显示 Loading 状态
        showLoading() {
            return !this.isSDKReady
        }
    },
    methods:{
        initListener() {
            // 登录成功后会触发 SDK_READY 事件，该事件触发后，可正常使用 SDK 接口
            this.tim.on(this.TIM.EVENT.SDK_READY, this.onReadyStateUpdate, this)
            // SDK NOT READT
            this.tim.on(this.TIM.EVENT.SDK_NOT_READY, this.onReadyStateUpdate, this)
            // 被踢出
            this.tim.on(this.TIM.EVENT.KICKED_OUT, this.onKickOut)
            // SDK内部出错
            this.tim.on(this.TIM.EVENT.ERROR, this.onError)
            // 收到新消息
            this.tim.on(this.TIM.EVENT.MESSAGE_RECEIVED, this.onReceiveMessage)
            // 会话列表更新
            this.tim.on(this.TIM.EVENT.CONVERSATION_LIST_UPDATED, this.onUpdateConversationList)
            // 群组列表更新
            this.tim.on(this.TIM.EVENT.GROUP_LIST_UPDATED, this.onUpdateGroupList)
            // 网络监测
            this.tim.on(this.TIM.EVENT.NET_STATE_CHANGE, this.onNetStateChange)
            // 已读回执
            this.tim.on(this.TIM.EVENT.MESSAGE_READ_BY_PEER, this.onMessageReadByPeer)

        },
        onReceiveMessage({ data: messageList }) {
            this.$store.commit('pushCurrentMessageList', messageList)
            // this.handleVideoMessage(messageList)
            // this.handleAt(messageList)
            // this.handleQuitGroupTip(messageList)

        },
        onError({ data }) {
            console.log(46455656565656)
            if (data.message !== 'Network Error') {
                this.$store.commit('showMessage', {
                    message: data.message,
                    type: 'error'
                })
            }
        },
        onMessageReadByPeer() {

        },
        onReadyStateUpdate({ name }) {
            console.log(name,'onReadyStateUpdateonReadyStateUpdateonReadyStateUpdateonReadyStateUpdateonReadyStateUpdateonReadyStateUpdateonReadyStateUpdateonReadyStateUpdateonReadyStateUpdateonReadyStateUpdate')
            const isSDKReady = name === this.TIM.EVENT.SDK_READY ? true : false
            this.$store.commit('toggleIsSDKReady', isSDKReady);
            this.$store.commit('setSdkReady', isSDKReady);
            console.log(isSDKReady,'isSDKReadyisSDKReady');
            if (isSDKReady) {
                this.tim
                    .getMyProfile()
                    .then(({ data }) => {
                        console.log(data)
                        // this.$store.commit('updateCurrentUserProfile', data)
                    })
                    .catch(error => {
                        this.$store.commit('showMessage', {
                            type: 'error',
                            message: error.message
                        })
                    })
                // this.$store.dispatch('getBlacklist')
            }
        },
        kickedOutReason(type) {
            switch (type) {
                case this.TIM.TYPES.KICKED_OUT_MULT_ACCOUNT:
                    return '由于多实例登录'
                case this.TIM.TYPES.KICKED_OUT_MULT_DEVICE:
                    return '由于多设备登录'
                case this.TIM.TYPES.KICKED_OUT_USERSIG_EXPIRED:
                    return '由于 userSig 过期'
                default:
                    return ''
            }
        },
        checkoutNetState(state) {
            switch (state) {
                case this.TIM.TYPES.NET_STATE_CONNECTED:
                    return { message: '已接入网络', type: 'success' }
                case this.TIM.TYPES.NET_STATE_CONNECTING:
                    return { message: '当前网络不稳定', type: 'warning' }
                case this.TIM.TYPES.NET_STATE_DISCONNECTED:
                    return { message: '当前网络不可用', type: 'error' }
                default:
                    return ''
            }
        },
        onNetStateChange(event) {
            this.$store.commit('showMessage', this.checkoutNetState(event.data.state))
        },
        onKickOut(event) {
            this.$store.commit('showMessage', {
                message: `${this.kickedOutReason(event.data.type)}被踢出，请重新登录。`,
                type: 'error'
            })
            this.$store.commit('user/toggleIsLogin', false)
            this.$store.commit('reset')
        },
        onUpdateConversationList(event) {
            console.log(event)
            this.$store.commit('updateAllConversation', event.data)
            // this.$store.commit('updateConversationList', event.data)
        },
        onUpdateGroupList(event) {
            console.log(event)
            // this.$store.commit('updateGroupList', event.data)
        },
        onReceiveGroupSystemNotice(event) {
            const isKickedout = event.data.type === 4
            const isCurrentConversation =
                `GROUP${event.data.message.payload.groupProfile.groupID}` ===
                this.currentConversation.conversationID
            // 在当前会话被踢，需reset当前会话
            if (isKickedout && isCurrentConversation) {
                this.$store.commit('resetCurrentConversation')
            }
            // Notification({
            //     title: '新系统通知',
            //     message: translateGroupSystemNotice(event.data.message),
            //     duration: 3000,
            //     onClick: () => {
            //         const SystemConversationID = '@TIM#SYSTEM'
            //         this.$store.dispatch('checkoutConversation', SystemConversationID)
            //     }
            // })
        },
        getUrlParams(par){ // 获取url上面的参数
            var local_url = document.location.href;
            var getstr = local_url.substr(local_url.indexOf('?')+1);
            var get = getstr.split('&');
            for(var i in get){
                if(get[i].indexOf(par+'=')>=0){
                    return get[i].replace(par+'=','');
                }
            }
            return false;
        },
        async screenRegister(){
            let cliType = this.getUrlParams('cliType') || '';
            let deviceId = this.getUrlParams('deviceId') || '';
            let ip =  this.getUrlParams('ip') || this.getUrlParams('wifi') || '';
            let deviceName =  this.getUrlParams('deviceName') || '';
            if(cliType && deviceId && ip){
                try {
                    let {code,data} = await api.screenRegister({
                        query: {cliType,deviceId,ip,deviceName},
                        method: "post",
                    }); // 硬件-注册设备
                    if(code === 200 && data){
                        localStorage.setItem('freqTimeInterval',data.freqTimeInterval || '');
                        localStorage.setItem('userId',data.imCommunicationId || '');
                        localStorage.setItem('roomInfo',data.roomIds || '');
                        localStorage.setItem('screenData',data)
                        this.$store.dispatch('login');
                    }
                }catch (e) {
                    // window.alert(JSON.stringify(e));
                }
            }
        }
    }
}
</script>
<style lang="scss">
    body{
        margin: 0;
        padding: 0;
    }
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  font-family: PingFangSC, PingFangSC-Semibold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
