
export function formatDuration (time) {
  let interval = time;
  let continued = '';
  if (interval > 3600) {
    const hour = Math.floor(interval / 3600);
    continued += hour + '小时';
    interval -= hour * 3600
  }
  if (interval > 60 && interval < 3600) {
    const min = Math.floor(interval / 60);
    continued += min + '分';
    interval -= min * 60
  }
  if (interval < 60) {
    continued += Math.floor(interval) + '秒'
  }
  return continued
}
export function isJSON (str) {
  if (typeof str === 'string') {
    try {
      let obj = JSON.parse(str);
      return !!(typeof obj === 'object' && obj)
    } catch (e) {
      return false
    }
  }
}
/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result, query
  console.log(args)
  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, query)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    query = args
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, query)
      context = args = null
    }

    return result
  }
}


// 浮点型加法函数
export function accAdd (arg1, arg2) {
  var r1, r2, m
  try {
    r1 = arg1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  m = Math.pow(10, Math.max(r1, r2))
  return ((arg1 * m + arg2 * m) / m).toFixed(2)
}

// 浮点型乘法
export function mul (a, b) {
  let c = 0
  let d = a.toString()
  let e = b.toString()

  try {
    c += d.split('.')[1].length
  } catch (f) {
    console.log(f)
  }
  try {
    c += e.split('.')[1].length
  } catch (f) {
    console.log(f)
  }
  return Number(d.replace('.', '')) * Number(e.replace('.', '')) / Math.pow(10, c)
}

// 浮点型除法
export function div (a, b) {
  var c, d, e = 0,
    f = 0
  try {
    e = a.toString().split('.')[1].length
  } catch (g) {
    console.log(g)
  }
  try {
    f = b.toString().split('.')[1].length
  } catch (g) {
    console.log(g)
  }
  return c = Number(a.toString().replace('.', '')), d = Number(b.toString().replace('.', '')), mul(c / d, Math.pow(10, f - e))
}

// 浮点减法
export function minus (arg1, arg2) {
  var r1, r2, m, n
  try { r1 = arg1.toString().split('.')[1].length } catch (e) { r1 = 0 }
  try { r2 = arg2.toString().split('.')[1].length } catch (e) { r2 = 0 }
  m = Math.pow(10, Math.max(r1, r2))
  // 动态控制精度长度
  n = (r1 >= r2) ? r1 : r2
  return ((arg1 * m - arg2 * m) / m).toFixed(n)
}
export function formatTime (date,format="MM-DD") {
  console.log(format);
  if (isToday(date)) {
    // return uni.dayjs(date).format('A HH:mm').replace('PM', '下午').replace('AM', '上午')
    // return uni.dayjs(date).format('HH:mm')
  } else if (isYesterday(date)){
    // return '昨天 '+uni.dayjs(date).format('HH:mm')
  }
  // return uni.dayjs(date).format(format)
}
export function isToday (date) {
  return date.toDateString() === new Date().toDateString()
}
export function isYesterday (date) {
  console.log(date)
  // return date.toDateString() === uni.dayjs().subtract(1, 'day').toDate().toDateString()
}
export function doTTS (ttsText){
  var ttsDiv = document.getElementById('bdtts_div_id');
  var ttsAudio = document.getElementById('tts_autio_id');
  // 这样就可实现播放内容的替换了
  ttsDiv.removeChild(ttsAudio);
  var au1 = '<audio id="tts_autio_id" autoplay="autoplay">';
  var sss = '<source id="tts_source_id" src="http://tts.baidu.com/text2audio?lan=zh&ie=UTF-8&spd=3&text='+ttsText+'" type="audio/mpeg">';
  var eee = '<embed id="tts_embed_id" height="0" width="0" src="">';
  var au2 = '</audio>';
  ttsDiv.innerHTML = au1 + sss + eee + au2;

  ttsAudio = document.getElementById('tts_autio_id');

  ttsAudio.play();
 }
export default {
  debounce,
  accAdd,
  mul,
  formatTime,
  div,
  minus
}
