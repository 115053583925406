import axios from 'axios'
// import Vue from 'vue'
// import router from './../../router'
import router from './../router'
// import { Toast } from 'vant'
// import 'vant/lib/toast/style'
// import api from './../api/index'
// Vue.use(Toast)

// axios.defaults.headers.common['Authorization'] = 'AUTH_TOKEN'
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  // console.log(config, 'config')
  // 每次请求前设置token
  let tokenHeader = window.localStorage.getItem('tokenHeader')
  let token = window.localStorage.getItem('token')
  let setToken;
  if (tokenHeader && token) {
    setToken = tokenHeader + ' ' + token
    config.headers.common['Authorization'] = setToken;
  }
  let url = config.url
  if (url.indexOf('/apis/api-member/api/distributor/login') > -1 || url.indexOf('/apis/api-member/api/distributor/checkDistributorRegister') > -1 || url.indexOf('apis/api-member/ums/UmsMember/generateCode') > -1) {
    // console.log('继续走')
  } else {
    // console.log('哈哈')
  }
  // 在发送请求之前做些什么
  return config
}, function (error) {
  // 对请求错误做些什么
  console.log(error, 'error')
  return Promise.reject(error)
})
// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  const res = response.data
  if (res.code === 200 || res.code === 0) {
    return response
  } else {
    if (res.code === 401) {
      router.push({name: 'login'})
    }
    return response
  }
  // return response
}, function (error) {
  if (error.response != null && error.response.data.msg != null && error.response.data.msg != '') {
    // Toast({
    //   duration: 1000,
    //   message: '系统异常，请稍后重试'
    // })
  } else{
    // Toast({
    //   duration: 1000,
    //   message: '系统异常，请稍后重试'
    // })
  }
  console.log(error.response.data.msg,'444')
  // 对响应错误做点什么
  return Promise.reject(error)
})
// method请求为post
export function postMethod (params, url) {
  let data = params.query || {}
  let header = params.header || {}
  return new Promise((resolve, reject) => {
    axios.request({
      url: url,
      method: 'post',
      data: data,
      headers: header || {'Content-Type': 'application/json'}
    }).then(res => {
      resolve(res.data)
    }).catch(error => {
      reject(error)
    })
  })
}
// method请求为GET
export function getMethod (params, url) {
  let data = params.query || {}
  let header = params.header || {}
  return new Promise((resolve, reject) => {
    axios.request({
      url: url,
      method: 'get',
      params: data,
      headers: header || {'Content-Type': 'application/x-www-form-urlencoded'}
    }).then(res => {
      resolve(res.data)
    }).catch(error => {
      reject(error)
    })
  })
}
// 获取验证token
/*
function getSession(){
  return new Promise((resolve, reject)=>{
    api.getSession({
      query: { "openId": this.model.phone },
      method: "post",
      header: {
        'client_id': 'app',
        'client_secret': 'app',
        'Content-Type': 'application/json;charset=UTF-8'
      }
    }).then(res =>{
      localStorage.setItem('token', res.access_token)
      localStorage.setItem('tokenHeader', res.token_type)
    })
  })
}
*/
