import {getMethod, postMethod} from './../utlis/request'
import getUrl from './faceData'




// 硬件-注册设备
const screenRegister = (params) => postMethod(params, getUrl('api', 'screenRegister'))


//im登录签名
const getUserSign = (params) => getMethod(params, getUrl('api', 'getUserSign'));

// 分诊屏
const getBatchRoomQueueStatus = (params) => getMethod(params, getUrl('api', 'getBatchRoomQueueStatus'));


export default {
  getBatchRoomQueueStatus,
  screenRegister,
  getUserSign
}
