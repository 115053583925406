

const state = {
	loginStatus: false
}

const mutations = {

}

const actions = {
	// 登陆
	login({
		commit,
		state
	}, wxCode) {
		return new Promise(async (resolve, reject) => {
			try {
				const {
					code,
					data,
					msg
				} = await login({
					code:wxCode
				});
				if (code === 200) {
					sessionStorage.setItem('toKen', data.token);
					uni.setStorageSync('toKen', data.token);
					uni.setStorageSync('userInfo', JSON.stringify(data.userInfo));
					resolve(data)
				} else {
					reject(msg)
				}
			} catch(e) {
				reject(e)
			}
		})
	},
}

// 获取IM历史消息
export function getTimHistoryMsgList (data) {
	return Http.request({
	  url: 'getTimHistoryMsgList',
	  data,
	  method: 'GET',
	})
  }
  // 注册tim用户
export function timUserRegister (data) {
	return Http.request({
	  url: 'timUserRegister',
	  data,
	  method: 'post',
	  hasLoading: true
	})
  }
// 获取IM历史消息
export function saveInquiryMsg (data) {
	return Http.request({
	  url: 'saveInquiryMsg',
	  data,
	  method: 'post',
	})
  }
// 签名
export function getUserSign (data) {
	return Http.request({
	  url: 'getUserSign',
	  data,
	  method: 'get',
	  header: {'content-type': 'application/x-www-form-urlencoded'}
	})
  }
export default {
	namespaced: true,
	state,
	mutations,
	actions
}
